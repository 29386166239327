
                  @import "src/styles/_variables";
                

.box {
    position: relative;
}

.input_video,
.output_canvas {
    transform: rotateY(180deg);
}

.output_canvas {
    position: absolute;
    top: 0;
    left: 0;
}

.search-mock {
    position: absolute;
    top: 100px;
    left: 0;
    font-weight: 900;
    color: white;
}

.record-sign {
    position: absolute;
    top: 10px;
    right: 20px;
    border: 1px solid black;
    background-color: black;
    opacity: 0.7;
    border-radius: 5px;
    padding: 3px 5px;
    color: white;
    font-size: 12px;
}

.countdown {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
}

.countdown-timer, .countdown-timer-bar {
    height: 8px;
    background-color: rgba(0, 0, 0, 0.2);
    border-radius: 0.15rem;
}

.countdown-timer {
    margin-bottom: 2rem;
}

.countdown-timer-bar {
    background-color: $accent-color;
    transform-origin: left;
}

@keyframes countdown {
    to {
        transform: scaleX(0);
    }
}

.animate {
    animation: countdown 4s linear forwards;
}

.btn-box {
    height: 40px;
}

