.search-header__label {
    font-size: $font-size-medium;
    color: $card-text-color;
}

.search-popover__header__label {
    color: $brand-color;
    font-size: $font-size-medium;
    @include media-breakpoint-up(md) {
        font-size: $font-size-big;
    }
}

.search-popover__header__info-text {
    color: $accent-color;
    background-color: transparent;
    border: none;
    white-space: nowrap;
}

.search-popover__header__info-button {
    @include media-breakpoint-up(md) {
        font-size: $font-size-very-big;
    }
    font-size: $font-size-huge;
}
