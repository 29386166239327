
                  @import "src/styles/_variables";
                

.empty-state {
    border: $thin-border-size solid $brand-color;
    border-radius: $default-border-radius;
    color: $background-text-color;
}

.empty-state__sign-ot-week__header {
    color: $brand-color;
    font-size: 1.5rem;
}

.empty-state__call-to-action {
    background-color: $header-color;
    color: $header-text-color;
}

.empty-state__call-to-action__text {
    font-family: $font-family-accent;
    text-align: center;
    letter-spacing: 0.05rem;
}

.icon-sign-buddy {
    height: 150px;
    filter: drop-shadow(4px 4px 1px #354e8a);
}
.help-btn{
    filter: drop-shadow(4px 4px 1px #354e8a);}
