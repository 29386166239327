
                  @import "src/styles/_variables";
                

@keyframes timer-slide-lt {
    0% {
        transform: rotate(135deg);
    }
    50% {
        transform: rotate(135deg);
    }
    100% {
        transform: rotate(315deg);
    }
}

@keyframes timer-slide-rt {
    0% {
        transform: rotate(-45deg);
    }
    50% {
        transform: rotate(135deg);
    }
    100% {
        transform: rotate(135deg);
    }
}

.timer {
    .circle-timer {
        width: 200px;
        height: 200px;
        margin: 2rem auto;
        position: relative;
        cursor: pointer;

        .timer-slot {
            position: relative;
            width: 100px;
            height: 200px;
            display: inline-block;
            overflow: hidden;
            float: left;

            .timer-lt, .timer-rt {
                border-radius: 50%;
                position: relative;
                top: 50%;
                left: 0;
                border: 10px solid white;
                width: 200px;
                height: 200px;
                margin-left: -100px;
                margin-top: -100px;
                border-left-color: transparent;
                border-top-color: transparent;
                z-index: 5;
            }

            .timer-lt {
                animation: 3s linear 1 timer-slide-lt;
                left: 100%;
            }

            .timer-rt {
                animation: 3s linear 1 timer-slide-rt;
            }
        }

        .count {
            position: absolute;
            width: 100%;
            height: 100%;
            font-size: 8rem;
            color: white;
            text-align: center;
            line-height: 200px;
            font-family: sans-serif;
        }
    }
}
