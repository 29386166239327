
                  @import "src/styles/_variables";
                

.search-option-card {
    // TODO: find cleaner way todo css for mobile
    overflow: hidden;
    white-space: nowrap;
    background-color: transparent;
    color: $background-color;
    height: 3.5rem;

    @include media-breakpoint-up(md) {
        border: $card-border;
        color: $card-text-color;
        background-color: $card-background-color;
        box-shadow: 0.05rem 0.05rem 0.25rem 0.05rem rgba(54, 79, 140, 0.25);
        border-radius: $default-border-radius;
        &:hover {
            box-shadow: $box-shadow-hover;
        }
    }
    &:hover {
        cursor: pointer;
    }

    &--active {
        background-color: $side-menu-selected-color;
        @include media-breakpoint-up(md) {
            box-shadow: none;
            color: $brand-color;
            background-color: $card-background-color;
            border-bottom: 0.15rem solid $brand-color;
        }
        &:hover {
            box-shadow: none;
            cursor: default;
        }
    }
}

.search-option-card__icon {
    font-size: $font-size-medium;
    height: 1.5rem;
}

.search-option-card__amount {
    position: absolute;
    top: 0.5rem;
}

.search-option-card__active-indicator {
    &--active__arrow {
        transform: rotate(90deg);
        transition-duration: 0.3s;
    }
    &--inactive__arrow {
        transition-duration: 0.3s;
    }
}
