
                  @import "src/styles/_variables";
                

.vgtc-svg-icon {
    height: 1rem;
    font-size: inherit;
    &--fill {
        fill: currentColor;
        path {
            fill: currentColor;
        }
    }
}
