
                  @import "src/styles/_variables";
                

.logo-box {
    &-vgtc {
        height: 180px;
    }

    &-ugent {
        height: 160px;
    }

    &-amai {
        height: 100px;
    }

    &-ai {
        height: 110px;
    }

    &-vvw {
        height: 80px;
    }
}

.icon-sign-buddy {
    height: 150px;
}
