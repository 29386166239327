.btn-admin {
  background-color: $admin-color;
  color: $admin-text-color;

  &:hover {
    background-color: $admin-color-hover;
    color: $admin-text-color-hover;
  }

  &--no-hover:hover {
    background-color: $admin-color;
    color: $admin-text-color;
  }
}

.btn {
  text-transform: uppercase;
}

.vgtc-button {
  align-items: center;
  display: flex;
  padding: 0.5rem 1rem 0.5rem 1rem;
  border-radius: $default-border-radius;
  color: $accent-text-color;
  background-color: $accent-color;
  border: $thin-border-size solid $accent-shadow-color;
  box-shadow: $box-shadow;
  text-align: start;
  font-size: $font-size-slightly-smaller;
  text-transform: uppercase;

  &:hover {
    color: $accent-text-color-hover;
    background-color: $accent-color-hover;
  }

  &--invert {
    background-color: transparent;
    color: $accent-color;
    font-weight: bold;
    border: none;
    box-shadow: none;

    &:hover {
      color: $accent-color-hover;
      background-color: transparent;
    }
  }

  &--color-brand {
    background-color: $brand-color;
    border: $thin-border-size solid $brand-color-dark;

    &:hover {
      background-color: $brand-color-dark;
    }
  }
}

.vgtc-button__icon {
  font-size: $font-size-big;
  margin-right: 0.5rem;
}

.vgtc-button-close {
  color: $card-text-color;
  right: -3px;
  top: -5px;
}
