
                  @import "src/styles/_variables";
                

.search-popover__card-container {
    position: relative;
    padding-top: 100%;
    width: 100%;
}

.search-popover__card {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: $category-card-background-color;
    border: $card-border;
    box-shadow: $box-shadow-light;
    border-radius: $default-border-radius;
    color: $category-card-front-color;

    &--selected {
        background-color: $brand-color;
        fill: $card-selected-svg-color;
        color: $card-selected-svg-color;
    }

    &--icon {
        width: 60% !important;
        height: 60% !important;
    }

    @media (hover: hover) {
        &:hover {
            cursor: pointer;
            filter: brightness(90%);
        }
    }
}

.search-popover__label {
    text-align: center;

    &--selected {
        color: $brand-color;
    }

    &--small {
        font-size: $font-size-small;
    }
}
