
                  @import "src/styles/_variables";
                

.gloss-detail__header__back {
    color: $accent-color;
    font-size: 0.875rem;

    &:hover {
        color: $accent-color-hover;
    }
}

.gloss-detail__variants__header__region-icon {
    height: 2rem;
    width: 3rem;
    color: $brand-color;
    path {
        fill: $footer-text-color;
    }
}

.gloss-detail__sign-list__icon {
    height: auto !important;
    width: 2rem !important;
    color: $brand-color;
}

.gloss-detail__variants__header__text {
    color: $text-title-color;
    font-size: $font-size-default;
    letter-spacing: 0.075rem;
    font-weight: normal;
    @include media-breakpoint-up(md) {
        font-size: $font-size-medium;
    }
}

.gloss-detail__signs__content {
    color: $card-text-color;
}

.gloss-detail__signs__content__icon {
    height: 1.5rem;
}

.quick-links {
    z-index: $zindex-popover;
    right: 0;
    bottom: 30%;
}

.quick-link-button {
    box-shadow: $box-shadow;
    width: 2.5rem;
    height: 2.5rem;
    color: white;
    border: none;
    background-color: $accent-color;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;

    &--thick {
        path {
            stroke-width: 2rem;
        }
    }
}

.m-left-95 {
    margin-left: 30px !important;
}
