
                  @import "src/styles/_variables";
                

.search-option-popover-container {
    background-color: $card-background-color;
    box-shadow: $box-shadow;
    border-radius: $default-border-radius;
    width: 100%;
    color: $card-text-color;
}

.search-option-popover {
    width: 100%;
    left: 0;
    z-index: $zindex-modal;
}
