
                  @import "src/styles/_variables";
                

.vgtc-side-menu__item,
.btn.vgtc-side-menu__item {
    color: $background-color;
    height: 3.5rem;
}

.btn.vgtc-side-menu__item {
    svg {
        font-size: $font-size-big;
    }
}
