
                  @import "src/styles/_variables";
                

.vgtc-hero.navbar {
    background-color: $header-color;
    box-shadow: $box-shadow-navbar;

    @include media-breakpoint-down(lg) {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        height: $navbar-height;
    }

    @include media-breakpoint-up(lg) {
        background-image: linear-gradient(to bottom, $brand-color-light, $brand-color);
        height: $navbar-hero-height;
    }
}

.vgtc-hero__content {
    @include media-breakpoint-up(lg) {
        background-image: url('../../../public/img/vgtc-banner-mute.png');
        background-position: center;
        background-repeat: no-repeat;
        top: -$navbar-hero-height + 6.25rem;
    }

    @include media-breakpoint-down(xl) {
        max-width: map-get($container-max-widths, xl);
    }
    @include media-breakpoint-down(lg) {
        max-width: map-get($container-max-widths, lg);
    }
    @include media-breakpoint-up(sm) {
        padding-left: $grid-gutter-width / 2;
        padding-right: $grid-gutter-width / 2;
    }
}

.vgtc-hero__content__text {
    color: $header-text-color;
    font-size: 2rem;
    font-family: $font-family-accent;
    text-align: center;
}

.vgtc-hero__content__text__languages {
    font-weight: bold;
}
