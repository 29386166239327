
                  @import "src/styles/_variables";
                

.missing_sign_video {
    background-color: $video-background-color;
    color: $header-text-color;
}

.missing_sign_video__img {
    max-height: 20rem;
}

.missing_sign_video__report-link {
    cursor: pointer;

    &:hover {
        color: darken($header-text-color, 15%);
    }
}

.missing_sign_video__report-link {
    text-decoration: underline;
}
