
                  @import "src/styles/_variables";
                

.search-results__overview {
    border-bottom: $divider;
}

.search-results__overview__text {
    color: $text-title-color;
    font-size: $font-size-medium;
    letter-spacing: 0.075rem;
    @include media-breakpoint-up(md) {
        font-size: $font-size-big;
    }
}

.search-results__no-results__img {
    height: 5rem;
}

.search-results__no-results__title {
    font-size: 1.375rem;
    font-family: $font-family-accent;
    font-weight: 500;
}

.search-results__no-results__help {
    font-family: $font-family-accent;
    font-weight: 500;
}

.search-results__no-results__new-search {
    color: $accent-color;
}

.search-results__no-results__new-search__icon {
    font-size: 1.75rem;
}
