
                  @import "src/styles/_variables";
                

.sign-detail-card__title {
    font-size: $font-size-medium;
}

.sign-detail-card__info-icon {
    background-color: transparent;
    border: none;
    font-size: $font-size-big;
}
