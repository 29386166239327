
                  @import "src/styles/_variables";
                

.feedback-modal__privacy-policy {
    font-size: $font-size-small;
}

.send-feedback-spinner {
    color: $accent-color;
    left: 0;
    right: 0;
}
