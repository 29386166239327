.feature-inactive {
    color: $disabled-color !important;
    fill: $disabled-color !important;
    opacity: 0.8 !important;
    pointer-events: none !important;

    &--background {
        background-color: $disabled-color !important;
        color: $disabled-text-color !important;
    }
}

.icon {
    &-dark {
        color: #273f7a;

        &-bold {
            font-weight: bold;
        }

        &-bolder {
            font-weight: bolder;
        }
    }
}

.text-underline {
    text-decoration: underline;
}

.text-bold {
    font-weight: bold;
}

.shaded-overlay {
    opacity: 0.5;
}

.color-brand {
    fill: $brand-color;
    color: $brand-color;
    &--background {
        background-color: $brand-color;
        color: $default-text-color;
    }
}

.color-accent {
    fill: $accent-color;
    color: $accent-color;
    &--background {
        background-color: $accent-color;
        color: $default-text-color;
    }
}

.divider {
    border-top: 1px inset $card-dividerline;
    flex-grow: 1;
    height: 1px;
    opacity: 0.5;
    &--light {
        opacity: 0.25;
    }
    &--vertical {
        border-top: unset;
        border-right: 1px inset $card-dividerline;
        height: unset;
        width: 1px;
        flex-grow: unset;
    }
}

.box-shadow-top {
    box-shadow: 0 -0.25rem 0.3rem 0 $shadow-color !important;
}

.box-shadow-bottom {
    box-shadow: 0 0.25rem 0.3rem 0 $shadow-color !important;
}

.box-shadow-none {
    box-shadow: none !important;
}

// media queries must be placed last in order to override the !important of the none media query css styles
.box-shadow-md-bottom {
    @include media-breakpoint-up(md) {
        box-shadow: 0 0.25rem 0.3rem 0 $shadow-color !important;
    }
}

.box-shadow-md-none {
    @include media-breakpoint-up(md) {
        box-shadow: none !important;
    }
}

.box-shadow-md-top {
    @include media-breakpoint-up(md) {
        box-shadow: 0 -0.25rem 0.3rem 0 $shadow-color !important;
    }
}

.freeze-page {
    overflow: hidden;
    height: 100%;
    position: relative;
}

.clickable {
    cursor: pointer;
}

// Vertical breakpoint helper classes

.flex-vsm-hsm-column {
    @media (max-height: $vsm-breakpoint) and (max-width: $hsm-breakpoint) {
        flex-direction: column !important;
        &-reverse {
            flex-direction: column-reverse !important;
        }
    }
}

.blurred-unclickable {
    transition: 500ms;
    opacity: 0.15;
    pointer-events: none;
}

.r-0 {
    right: 0 !important;
}

.b-0 {
    bottom: 0 !important;
}
