
                  @import "src/styles/_variables";
                

.info-modal__button {
    border-radius: $default-border-radius;
    color: $accent-text-color;
    background-color: $accent-color;
    border: $thin-border-size solid $accent-shadow-color;
    box-shadow: $box-shadow;
    text-align: start;

    &:hover {
        color: $accent-text-color-hover;
        background-color: $accent-color-hover;
    }
}

.info-modal__button__comment {
    font-size: $font-size-big;
}

.info-modal__header__text {
    color: $text-title-color;
    font-size: $font-size-medium;
    @include media-breakpoint-up(md) {
        font-size: $font-size-big;
    }
}

.info-modal__header__icon {
    color: $brand-color;
    font-size: $font-size-medium;

    &--big {
        font-size: $font-size-very-big;
    }
}

.info-modal__link__icon {
    font-size: $font-size-big;
}

.info-modal__link {
    @include media-breakpoint-down(sm) {
        font-size: 0.95rem;
    }
}

.video-element {
    @include media-breakpoint-up(md) {
        position: sticky !important;
        top: 0;
        align-self: flex-start;
    }
}
