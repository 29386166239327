
                  @import "src/styles/_variables";
                

.amount-indicator {
    font-size: $font-size-medium;
    border: $thin-border-size solid $card-text-color;
    border-radius: 1.125rem;
    display: inline-block;
    min-width: 2.25rem;
    min-height: 2.25rem;
    padding: 0.125rem 0.5rem;

    &--size-small {
        $size: 1.25rem;
        min-width: $size;
        min-height: $size;
        border-radius: $size;
        font-size: 0.75rem;
        padding: 0;
        padding-left: 0.125rem;
    }

    &--type-accent {
        background-color: $accent-color;
        color: $accent-text-color;
        border-color: $accent-color;
    }
}

.amount-indicator__number {
    height: 100%;
}
