body {
    background-color: $background-color;
    font-family: $font-family;
    height: 100%;
}

// :focus {
//     outline: none;
//     box-shadow: 0 0 0.1rem 0.1rem $brand-color-light;
//     border-radius: $default-border-radius;
// }

.space-to-footer {
    min-height: 5rem;
}

.disabled-component {
    pointer-events: none;
}

.link {
    color: $link-color;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
        color: $link-hover-color;
    }
}
