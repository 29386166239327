
                  @import "src/styles/_variables";
                

.input-frame {
    border: solid 0.1rem $brand-color !important;
    border-top: 0 !important;
    border-top-left-radius: 0 !important;
    border-top-right-radius: 0 !important;
    border-bottom-left-radius: $default-border-radius !important;
    border-bottom-right-radius: $default-border-radius !important;
}
