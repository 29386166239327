
                  @import "src/styles/_variables";
                

.cookie-banner {
    position: fixed;
    bottom: 0;
    min-height: 6rem;
    width: 100%;
    background: $cookie-banner-color;
    color: $cookie-banner-text-color;
    font-size: $font-size-small;
    opacity: 0.95;
    z-index: $zindex-cookie-banner;

    @include media-breakpoint-up(md) {
        font-size: $font-size-slightly-smaller;
    }
}

.cookie-banner__cookie {
    height: 4rem;
    width: 4rem;
}

.cookie-banner__link {
    color: $accent-color-light;

    &:hover {
        color: $accent-color-hover;
    }
}

.vgtc-button.cookie-banner__button {
    white-space: nowrap;
    background-color: $accent-color;

    &--refuse {
        background-color: transparent;
        border-color: $cookie-banner-text-color;
    }
}
