
                  @import "src/styles/_variables";
                

.step-cell {
    background-color: $accent-color;
    color: white;
    width: 65px;
    height: 45px;
    font-size: 20px;
    font-weight: bold;
    border-radius: 0.2rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 15px;
}
