
                  @import "src/styles/_variables";
                

.vgtc-button--disabled {
    align-items: center;
    display: flex;
    padding: 0.5rem 1rem 0.5rem 1rem;
    border-radius: $default-border-radius;
    color: $disabled-text-color;
    background-color: $disabled-color;
    border: $thin-border-size solid $disabled-color;
}

.cancel-button {
    cursor: pointer;
}
