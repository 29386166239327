// ONLY VARIABLES HERE!! (because of auto import in vue.config.js)

$brand-color: #4362ad;
$brand-color-light: #567fb9;
$brand-color-dark: #273f7a;

$video-background-color: #638ac0;

$header-color: $brand-color;
$header-text-color: #ffffff;
$header-text-size: 1.125rem;
$default-text-color: #ffffff;

$cookie-banner-color: darken($brand-color, 35%);
$cookie-banner-text-color: #ffffff;
$zindex-cookie-banner: 1080;

$text-title-color: $brand-color;

$accent-color: #F3705D;
$accent-color-light: lighten($accent-color, 10%);
$accent-color-hover: darken($accent-color, 10%);
$accent-text-color: #ffffff;
$accent-text-color-hover: darken($accent-text-color, 10%);
$accent-shadow-color: darken($accent-color, 5%);

$record-button-color: #cd0000;
$record-button-stroke-color: #9b0000;

$admin-color: #614a12;
$admin-color-hover: darken($admin-color, 10%);
$admin-text-color: #ffffff;
$admin-text-color-hover: darken($admin-text-color, 10%);

$background-color: #f3f4f9;
$background-divider-color: #b5b5b5;
$background-text-color: #616369;
$background-tag-color: $brand-color;
$background-tag-text-color: #ffffff;

$disabled-color: #b5b5b5;
$disabled-text-color: #ffffff;

$playback-control-color: #252527;
$playback-control-light-color: #e1e1ee;
$playback-control-background-color: #ffffff;

$shadow-color: rgba(54, 79, 140, 0.25);
$secondary-shadow-color: rgba(62, 62, 64, 0.1);
$shadow-blur: 0.25rem;

$default-border-radius: 0.2rem;

$thin-border-size: 0.0625rem;
$medium-border-size: 0.15rem;
$thick-border-size: 0.25rem;

$related-search-result-border-color: rgba(121, 114, 114, 0.4);

$card-background-color: #ffffff;
$card-text-color: #666060;
$card-border-color: rgba(54, 79, 10, 0.25);
$card-border: $thin-border-size solid $card-border-color;
$card-shadow-color: $shadow-color;
$card-selected-svg-color: #ffffff;

$divider: $thin-border-size solid rgba($background-divider-color, 0.5);

$category-card-background-color: #f2f2f5;
$category-card-front-color: #8c8484;

$side-menu-selected-color: $brand-color-dark;

$box-shadow: 0.05rem 0.05rem $shadow-blur 0.05rem $shadow-color;
$box-shadow-navbar: 0 0.0625rem 0.125rem rgba(79, 79, 82, 0.5);
$box-shadow-searchbar: 0 0.2rem $shadow-blur 0 rgba(0, 0, 0, 0.35);
$box-shadow-searchbar-long: 0 0.2rem $shadow-blur 0 rgba(0, 0, 0, 0.14);
$box-shadow-light: 0 0.125rem $shadow-blur 0 $shadow-color;
$box-shadow-hover: 0.05rem 0.2rem $shadow-blur 0.05rem $shadow-color;
$box-shadow-side-menu: 0 0.25rem 0.1rem -0.05rem $shadow-color;

$border-searchbar: 0.08rem solid rgba(62, 91, 161, 0.5);

$font-size-small: 0.75rem;
$font-size-slightly-smaller: 0.875rem;
$font-size-default: 1rem;
$font-size-medium: 1.25rem;
$font-size-big: 1.5rem;
$font-size-very-big: 2rem;
$font-size-huge: 2.5rem;

$navbar-height: 6.25rem;
$navbar-hero-height: 23.75rem;
$navbar-mobile-hero-height: calc(5rem + 3vw);

$gutter: 1rem;

$footer-height: 15rem;
$footer-background-color: #3d3e40;
$footer-text-color: #d7d7d9;

$selection-indicator-color: #979797;
$selection-indicator-color-hover: darken($selection-indicator-color, 20%);

$font-family: 'Roboto';
$font-family-serif: 'Roboto Slab';
$font-family-accent: 'Raleway';

$card-dividerline: #979797;
$sub-title-color: #666060;

$search-type-icon-color: #666060;
$link-color: $accent-color;
$link-hover-color: $brand-color-dark;

$zindex-fullscreen: 1090;

$theme-colors: (
    'primary': $brand-color,
);
$vsm-breakpoint: 630px; // Vertical small breakpoint for zoom buttons of location
$hsm-breakpoint: 576px; // bootstrap xs viewport

@import '../../node_modules/bootstrap/scss/functions';
@import '../../node_modules/bootstrap/scss/variables';
@import '../../node_modules/bootstrap/scss/mixins/';
