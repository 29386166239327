@import url('https://fonts.googleapis.com/css?family=Raleway:normal,500,bold');
// @import url('https://fonts.googleapis.com/css?family=Roboto:100,300,500,700,900&display=swap'); // uncomment if you need any other font-weight than 400 (normal)
@import url('https://fonts.googleapis.com/css?family=Roboto Slab');
@font-face {
    font-family: 'Roboto';
    src: url('./fonts/Roboto-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.text-normal-small {
    font-size: 0.9375rem;

    &--wide {
        letter-spacing: 0.09375rem;
    }
}

.text-medium {
    font-size: $font-size-medium;
}

.text-large {
    font-size: $font-size-big;
}
