
                  @import "src/styles/_variables";
                

.sign-of-the-week {
    border: $card-border;
}

.sign-column-1 {
    border-right: 1px solid $card-border-color;
}
