
                  @import "src/styles/_variables";
                

.sign-match {
    border: dotted 2px #00C0AD;
    border-radius: 8px;

    &-icon {
        position: absolute;
        right: -20px;
        top: -20px;
    }
}

.found-match-icon {
    position: absolute;
    left: 13px;
}
