
                  @import "src/styles/_variables";
                

.sub-title {
    color: $sub-title-color;
}

.sub-title__text {
    letter-spacing: 0.1625rem;
}

.related-results-card {
    border: $card-border;
    border-radius: $default-border-radius;
    box-shadow: 1px 2px 2px 0 rgba(62, 62, 64, 0.1);
}

.related-results-card__overview__text {
    color: $text-title-color;
    font-size: $font-size-medium;
    letter-spacing: 0.075rem;
    @include media-breakpoint-up(md) {
        font-size: $font-size-big;
    }
}
