
                  @import "src/styles/_variables";
                

.contact-info__logo__background {
    background: $footer-background-color;
}

.contact-info__logo {
    img {
        width: 75%;
    }
}

.contact-info {
    background-color: white;
    height: 100%;
}

.contact-info__address {
    font-size: $font-size-small;
    font-style: italic;
    letter-spacing: 0.03rem;
}

.contact-info__link {
    letter-spacing: 0.03rem;
    color: $footer-background-color;
    font-size: $font-size-default;
    a {
        color: $footer-background-color;
        font-size: $font-size-default;
    }

    &--small {
        font-size: 0.85rem;
    }
}

.contact-info__link__icon {
    width: 1.5rem !important;
    height: 1.5rem !important;
    fill: $footer-background-color;
    color: $footer-background-color;
    &--ae {
        width: 2.2rem !important;
        height: auto !important ;
    }
}
