
                  @import "src/styles/_variables";
                

.mobile-space {
    background-color: $brand-color;
    //background-image: linear-gradient(to bottom, $brand-color-light, $brand-color);
    background-image: url('../../public/img/vgtc-banner-bright.png');
    background-image: url('../../public/img/vgtc-banner-bright.png'), linear-gradient(to bottom, $brand-color-light, $brand-color);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: $navbar-mobile-hero-height;
    transition: 0.3s;

    @include media-breakpoint-down(sm) {
        &--hide {
            margin-top: calc(#{$navbar-mobile-hero-height} * -1);
            transition: 0.5s;
        }
    }
}

.mobile-space__text {
    white-space: nowrap;
    //top: $navbar-mobile-hero-height - 2.5rem;
    bottom: 0.25rem;
    font-size: calc(0.35rem + 3vw);
    color: $header-text-color;
    letter-spacing: 0.04rem;
    font-family: $font-family-accent;
}

.mobile-space__link,
.btn.mobile-space__link {
    color: $header-text-color;
    font-size: $header-text-size;

    &:hover {
        background-color: $brand-color-dark;
        border-radius: $default-border-radius;
        color: $header-text-color;
        text-decoration: none;
    }
}
