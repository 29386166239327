.tooltip--accent {
    &.tooltip {
        opacity: 1;
    }
    .arrow {
        &::before {
            border-top-color: $accent-color;
        }
    }
    .tooltip-inner {
        background-color: $background-color;
        border: $thin-border-size solid $accent-color;
        color: $accent-color;
    }
}

.tooltip--disabled {
    &.tooltip {
        opacity: 1;
    }
    .arrow {
        &::before {
            border-top-color: $disabled-color;
        }
    }
    .tooltip-inner {
        background-color: $background-color;
        border: $thin-border-size solid $disabled-color;
        color: $disabled-color;
    }
}
