
                  @import "src/styles/_variables";
                

.sign-feedback {
    &__header__text {
        font-size: 1.5rem;
        color: $text-title-color;
        font-weight: bold;
        font-family: $font-family-accent;
        letter-spacing: 0.075rem;
        @include media-breakpoint-up(sm) {
            font-size: 2.1rem;
        }
    }

    &__sub-header__text {
        font-size: 1.25rem;
        color: $text-title-color;
    }

    &__consent__text {
        font-size: 0.75rem;
    }
}
