
                  @import "src/styles/_variables";
                

.vgtc-navbar {
    background-color: $header-color;
}

.vgtc-navbar__content {
    @include media-breakpoint-down(xl) {
        max-width: map-get($container-max-widths, xl);
    }
    @include media-breakpoint-down(lg) {
        max-width: map-get($container-max-widths, lg);
    }
    @include media-breakpoint-up(sm) {
        padding-left: $grid-gutter-width / 2;
        padding-right: $grid-gutter-width / 2;
    }
}
