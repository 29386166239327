
                  @import "src/styles/_variables";
                
@import 'variables';

@import '../../node_modules/bootstrap/scss/bootstrap.scss';
@import '../../node_modules/bootstrap-vue/dist/bootstrap-vue.css';

@import 'vue-bootstrap-checkbox';

@import 'buttons';
@import 'general';
@import 'animations';
@import 'fonts';
@import 'util';
@import 'vue-burger-custom';
@import 'card';
@import 'shared';
@import 'vue-loading-overlay';
@import 'modal';
@import 'tooltip';
