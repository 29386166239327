
                  @import "src/styles/_variables";
                

.count-down-button__label {
    color: white;
    fill: white;
    font-size: 1.5rem;
}

.count-down-button__count-down {
    stroke: $accent-text-color;
}

.count-down-button__background {
    fill: $record-button-color;
}

.count-down-button__pulse-background {
    fill: $record-button-stroke-color;
    stroke: fadeout($record-button-stroke-color, 50);
}
