.menu-button {
    background-color: transparent;
    justify-content: space-evenly;
    border: none;
    display: flex;
    flex-direction: column;
    margin-right: 0.8rem;
    margin-left: 0.3rem;
    height: 2.25rem;
    align-self: center;

    @include media-breakpoint-up(sm) {
        margin-left: -0.5rem;
    }
}

.menu-button__icon-bar {
    width: 1.7rem;
    height: 0.12rem;
    background-color: $background-color;
    border-radius: 5rem;
}

.vgtc-side-menu {
    .vgtc-side-menu__content {
        box-shadow: inset 0 0.2rem 0.2rem 0 rgba($brand-color-dark, 0.5);
    }

    &.vgtc-side-menu--open {
        .bm-menu {
            transition: 0.5s;
        }
    }

    .bm-menu {
        //margin-top: $navbar-height;
        background-color: $brand-color; //$brand-color-light;
        z-index: $zindex-popover;
        box-shadow: 0.25rem 0 0.25rem 0 rgba($brand-color-dark, 0.5);
        transition: 0s;
    }

    .bm-item-list {
        margin: 0;
        height: 100%;
        border-top: $medium-border-size solid $shadow-color;
        background-color: $brand-color;
        & > * {
            padding: 0;
        }

        .menu-item {
            box-shadow: $box-shadow-side-menu;
            cursor: pointer;
            min-width: 300px;
        }
    }

    .bm-cross {
        //display: none;
        background: $background-color;
        width: 0.25rem !important;
        height: 1.5rem !important;
        margin-top: auto;
        margin-bottom: auto;
        top: 0;
        bottom: 0;
        border-radius: 5rem;
    }

    .bm-cross-button {
        display: flex;
        justify-content: center;
        height: 2.5rem;
        width: 2.5rem;
        margin-right: 0.5rem;
    }
}
