
                  @import "src/styles/_variables";
                

.video-player--fullscreen {
    position: fixed !important;
    top: 0;
    left: 0;
    height: 100% !important;
    width: 100% !important;
    padding: 0 !important;
    z-index: $zindex-fullscreen;
    background-color: $playback-control-background-color;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    overflow: hidden;
}

.video-player__container {
    &--fullscreen {
        padding-bottom: unset;
        height: 100%;
    }
    overflow: hidden;
    width: 100%;
    height: 0;
    padding-bottom: 75%; // Aspect ratio 4:3
    position: relative;
    background-color: $video-background-color;
}

.video-player__video {
    position: absolute;
    top: 0;
    left: 0;
    object-fit: cover;
    // object-fit cover for IE and Edge
    font-family: 'object-fit: cover';
    width: 100%;
    height: 100%;
    &--fullscreen {
        object-fit: contain;
        font-family: 'object-fit: contain';
    }
}

.video-player__video-missing {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    color: $default-text-color;
}

.video-player__controls {
    border-bottom: 1px solid $background-divider-color;
    &--fullscreen {
        background-color: $playback-control-background-color;
        border-bottom: none;
    }
}

.video-player__controls__button {
    color: $playback-control-color;
    background-color: transparent;
    border: none;
    font-size: 1rem;
    padding: 0 0.5rem 0 0.5rem;
    margin-right: 0.25rem;
    &--fullscreen {
        font-size: 2rem;
    }

    &--highlighted {
        color: $accent-color;
    }
}

.video-player__controls__button:disabled {
  color: $playback-control-light-color;
}

.video-player__controls__duration {
    font-size: 0.8rem;
}
