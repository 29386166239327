
                  @import "src/styles/_variables";
                

.search-filter-tag-list__item {
    &:not(:first-child):not(:nth-child(2))::before {
        content: '+';
        font-size: $font-size-medium;
        margin: 0 0.5rem;
    }
}
.search-filter-tag-list__title {
    width: 100%;
    @include media-breakpoint-up(md) {
        width: unset;
    }
}

.search-filter-tag-list__remove-all {
    font-size: 0.875rem;
    border: none;
    background-color: transparent;
}

.search-filter-tag-list__remove-all__icon {
    font-size: $font-size-big;
}
