
                  @import "src/styles/_variables";
                

.search-filter-tag {
    border: none;
    background-color: $background-tag-color;
    color: $background-tag-text-color;
    border-radius: 0.3rem;
    font-size: 0.9rem;
    @include media-breakpoint-up(md) {
        font-size: 1rem;
    }
}
.search-filter-tag__text {
    border-right: solid 1px rgba($background-tag-text-color, 0.4);
}

.search-filter-tag__icon {
    font-size: 1.25rem;
}
.search-filter-tag__prefix {
    &--text {
        font-family: $font-family-serif;
    }
}
