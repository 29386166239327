
                  @import "src/styles/_variables";
                

.vgtc-logo {
    display: inline-block;

    svg {
        fill: white;
        width: 100px;
        height: 100px;
    }
}
