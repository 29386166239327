.modal-title {
    font-family: $font-family-accent;
    text-align: center;
    letter-spacing: 0.05rem;
    font-weight: bold;

    &--supertext {
        font-weight: bold;
    }

    &--subtext {
        font-size: $font-size-slightly-smaller;
    }
}

.modal-body {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.root__info-modal.modal{
    top:0 !important;
    display: flex;
}

@include media-breakpoint-down(md){
    .modal-dialog-scrollable{
        max-height: calc(95% - 1rem) !important;
    }
}