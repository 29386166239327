
                  @import "src/styles/_variables";
                

.login__form {
    width: 22rem;
}

.login__form__forgot-password {
    color: $accent-color;
    cursor: pointer;

    &:hover {
        color: $accent-color-hover;
    }
}
