
                  @import "src/styles/_variables";
                

.sign-feedback {
    &__sub-header__text {
        font-size: 1.25rem;
        color: $text-title-color;
    }

    &__tile {
        width: 120px;
        height: 120px;
        background-color: $category-card-background-color;
        border: $card-border;
        box-shadow: $box-shadow-light;
        border-radius: $default-border-radius;
        color: $category-card-front-color;
        cursor: pointer;

        &--selected {
            border-color: $brand-color;
            color: $brand-color;
        }
    }
}


.text-highlight {
    color: $accent-color;
}

.option-icon {
    height: 30px;
}
