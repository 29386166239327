
                  @import "src/styles/_variables";
                

.sign-feedback {
    &__sub-header__text {
        font-size: 1.25rem;
        color: $text-title-color;
    }

    &__consent__text {
        font-size: 0.75rem;
    }
}

.logo-box {
    &-vgtc {
        height: 190px;
    }

    &-ugent {
        height: 170px;
    }

    &-amai {
        height: 110px;
    }
}

.icon-sign-buddy {
    height: 150px;

    &--small {
        height: 45px;
        margin-left: -4px;
        margin-top: -5px;
    }
}
