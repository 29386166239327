
                  @import "src/styles/_variables";
                

.custom-file-input {
    white-space: nowrap;
    cursor: pointer;
}

.custom-file-label {
    cursor: pointer;
    border: none !important;
    white-space: nowrap;
    color: transparent !important;
    text-overflow: clip;
    overflow: hidden;
    &::after {
        left: 0;
        text-align: center;
        border-radius: $default-border-radius !important;
        background-color: $accent-color !important;
        color: $accent-text-color !important;
        border: $medium-border-size solid $accent-color !important;
    }
}
