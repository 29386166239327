
                  @import "src/styles/_variables";
                

.header__link,
.btn.header__link,
.header__link a {
    color: $header-text-color;
    font-size: $header-text-size !important;

    &:hover {
        background-color: $brand-color-dark;
        border-radius: $default-border-radius;
        color: $header-text-color;
        text-decoration: none;
    }

    &--back {
        font-size: 0.875rem;
    }
}

.header__button__icon {
    background-color: transparent;
    background: transparent;
    border: none;
}
