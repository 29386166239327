
                  @import "src/styles/_variables";
                

.video-recorder__button {
    outline: none !important;
    box-shadow: none !important;
    svg {
        width: calc(10vw + 1rem) !important;
        height: auto;
        @include media-breakpoint-up(sm) {
            width: 4rem !important;
        }
        @include media-breakpoint-up(md) {
            width: 5rem !important;
        }
    }

    &--play-pause {
        background-color: white;
        border-radius: 50%;
        color: $brand-color !important;
        &:hover {
            color: $brand-color-dark !important;
        }
    }
}

.video-recorder__button__redo {
    bottom: 0;
    top: 0;
}

.video-recorder__button-container {
    z-index: $zindex-popover;
    bottom: -0.07rem;
    position: absolute;
    &--top {
        bottom: 0;
        top: 0;
        right: 0;
    }
}
