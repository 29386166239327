
                  @import "src/styles/_variables";
                

$input-height: 2.5rem;
.vgtc-searchbar {
    position: relative;

    .list-group-item.active {
        background-color: $background-tag-color;
        border-color: $background-tag-color;
        color: $background-tag-text-color;
    }
}

.vgtc-searchbar__input-prefix {
    color: $background-divider-color;
    position: absolute;
    width: 1 + $input-height;
    font-family: $font-family-serif;
    font-size: 1.5rem;
    height: $input-height;
}

.vgtc-searchbar__input {
    border-radius: 0.4rem;
    box-shadow: $box-shadow-searchbar;
    background-color: $background-color;
    color: $background-text-color;
    border: $border-searchbar;
    @include media-breakpoint-up(md) {
        border-radius: 0.2rem;
        box-shadow: $box-shadow-searchbar-long;
    }
    height: $input-height;
    padding: 0;
    padding-left: 1rem;

    &::placeholder {
        color: $background-divider-color;
        font-style: italic;
    }

    @include media-breakpoint-up(sm) {
        padding-left: 1 + $input-height;
    }

    width: 100%;

    &::-webkit-search-decoration,
    &::-webkit-search-cancel-button,
    &::-webkit-search-results-button,
    &::-webkit-search-results-decoration {
        display: none;
    }
}

.vgtc-searchbar__input-suffix {
    color: $accent-color;
    position: absolute;
    width: 1 + $input-height;
    font-family: $font-family-serif;
    font-size: 1.25rem;
    right: 0;
    height: $input-height;
    cursor: pointer;
}

.vgtc-searchbar__input__suggestion__icon {
    width: 3rem;
    text-align: center;

    &--no-category {
        font-family: $font-family-serif;
    }
}
