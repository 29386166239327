
                  @import "src/styles/_variables";
                

.search-result__info {
    color: $accent-color;
    font-size: 1.125rem;
    font-weight: bold;
}

.search-result__region {
    color: $card-text-color;
}

.search-result__region__icon {
    height: 1.5rem;
    width: 2.5rem;
}

.btn.search-result__info__button {
    background-color: $accent-color;
    color: $accent-text-color;

    &:hover {
        color: $accent-text-color-hover;
        background-color: $accent-color-hover;
    }
}

.label-badge {
    background-color: $accent-color;
    color: $accent-text-color;
    font-weight: normal !important;
    margin-right: 3px;
}
