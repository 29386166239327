
                  @import "src/styles/_variables";
                

.binary-context-switch__button {
    padding: 0.5rem;
    border: solid 0.1rem $brand-color;
    color: $card-text-color;
    background-color: transparent;
    box-shadow: none !important;
    outline: none !important;
    max-width: 50%;
    &--first {
        border-top-left-radius: $default-border-radius !important;
        border-bottom-left-radius: 0 !important;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
    &--second {
        border-top-right-radius: $default-border-radius !important;
        border-bottom-right-radius: 0 !important;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    &--selected {
        background-color: $brand-color;
        color: $default-text-color !important;
        fill: $default-text-color !important;
    }
}

.binary-context-switch__button__icon {
    padding: 0.5rem;
    border-radius: $default-border-radius;
    font-size: 3rem;
    @include media-breakpoint-up(md) {
        font-size: 4rem;
    }
}

.binary-context-switch__button-label {
    font-size: $font-size-small;
    @include media-breakpoint-up(md) {
        font-size: $font-size-default;
    }
}
