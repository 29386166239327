
                  @import "src/styles/_variables";
                

.footer {
    height: $footer-height;
    background-color: $footer-background-color;
    color: $footer-text-color;
    bottom: 0;
    left: 0;
    right: 0;
}

.footer__text {
    &--address {
        font-size: $font-size-small;
        font-style: italic;
        letter-spacing: 0.03rem;
    }

    &--links {
        a {
            font-size: $font-size-small;
            color: $footer-text-color;
        }
    }
}

.footer__social-media {
    svg {
        color: $footer-text-color;
        fill: $footer-text-color;
    }
}

.footer__social-media__ae {
    text-decoration: none;

    svg {
        height: 1.8rem;
    }

    &:link {
        text-decoration: none;
    }

    &:hover {
        text-decoration: none;
    }

    &:active {
        text-decoration: none;
    }

    &:visited {
        text-decoration: none;
    }
}

.footer__social-media__ae__year {
    font-size: $font-size-small;
    color: $footer-text-color;
}

.contact-info__link__icon {
    width: 1.5rem !important;
    height: 1.5rem !important;
    fill: $footer-background-color;
    color: $footer-background-color;

    &--ae {
        width: 2.2rem !important;
        height: auto !important;
    }
}
